import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { HandleSubscriptionsComponent } from 'src/app/appDirectives/handle-subscriptions.directive';
import { DesignUtilityServices } from '../../utilities/design-utility.service';
import { CashMgmtService } from 'src/app/appServices/cash-mgmt.service';
import { ToastMessageService } from '../../utilities/toast-message.service';
import { IStore } from 'src/app/appModels/IStore';
import { StoreResolver } from 'src/app/appResolvers/store.resolver';

@Component({
  selector: 'app-add-initial-cash',
  templateUrl: './add-initial-cash.component.html',
  styleUrls: ['./add-initial-cash.component.scss'],
})
export class AddInitialCashComponent extends HandleSubscriptionsComponent implements OnInit {

  @Input() intialCash:any;
  
  loading: boolean = false;
  Form: UntypedFormGroup;
  validator = this._du.validator;
  store: IStore;

  constructor(
    private modalCtrl: ModalController,
    private _storeResolver: StoreResolver,
    private _du: DesignUtilityServices,
    private toaster: ToastMessageService,
    private cashMgmtService: CashMgmtService,
    private _fb: UntypedFormBuilder
  ) {
    super();
  }


  ngOnInit() {
    this.initForm();
    this.checkInitialCash();
    this.subscribeSelectedStore();
  }

  

  /**
   * Subscribe Selected Store 
   */
  subscribeSelectedStore() {
    this._storeResolver.selectedStore.subscribe(res =>{
      if(res){
        this.store = res;
      }
    });
  }

  /**
   * Check initial cash
   */
  checkInitialCash(){
    if(this.intialCash){
      this.Form.get('initialCash').patchValue(+this.intialCash.value);
    }
  }

  /**
   * init
   */
  initForm() {
    this.Form = this._fb.group({
      initialCash: ['', [Validators.required, Validators.min(0)]],
    });
  }

  /**
   * on Submit
   */
  onSubmit() {
    this.loading = true;
    if (this.Form.invalid) {
      return;
    }

    const payload = {
      ...this.intialCash,
      value: +this.Form.value.initialCash
    }
    this.handleSubscription(this.cashMgmtService.updateConfiguration(payload),
      (res) => {
        this.cashMgmtService.getIntialCash(this.store.id);
        this.toaster.successToastWithClose('Initial Cash Added');
        this.loading = false;
        this.closeModal('success');
      },
      (err) => {
        this.toaster.errorToastWithClose('Error: '+ err);
        this.loading = false;
        this.closeModal('error');
      }
    );

  }



  closeModal(action?:string) {
    this.modalCtrl.dismiss(action || 'closed');
  }


}
