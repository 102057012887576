import { IRole } from "../appModels/IRole";

export const Nav = [
    // { label: 'Dashboard', url: 'dashboard', icon: 'grid-outline', roles: [IRole.Admin] },
    { label: 'Guest', url: 'guest', icon: 'people', roles: [IRole.Admin, IRole.Cashier] },
    { label: 'POS', url: 'pos', icon: 'storefront', roles: [IRole.Admin, IRole.Cashier] },
    { label: 'Orders', url: 'transactions', icon: 'albums', roles: [IRole.Admin, IRole.Cashier] },
    { label: 'CDS', url: 'cds', icon: 'tv', roles: [IRole.Admin, IRole.Cashier] },
    { label: 'Menu', url: 'outlet', icon: 'grid', roles: [IRole.Admin, IRole.Cashier] },
    { label: 'Manufacturing', businessType: 'cannabis', url: 'manufacturing', icon: 'color-filter', roles: [IRole.Admin, IRole.Cashier] },
    { label: 'Settings', url: 'settings', icon: 'settings', roles: [IRole.Admin, IRole.Cashier] },
];