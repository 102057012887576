import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import emailjs, { EmailJSResponseStatus } from 'emailjs-com';
import { ModalController } from '@ionic/angular';
import { ToastMessageService } from 'src/app/core/utilities/toast-message.service';

@Component({
  selector: 'app-user-report',
  templateUrl: './user-report.component.html',
  styleUrls: ['./user-report.component.scss']
})
export class UserReportComponent implements OnInit {
  reportType: string = 'bug';
  bugReport = {
    page: '',
    appBehavior: '',
    expectedBehavior: '',
    additionalInfo: ''
  };
  featureRequest = {
    description: '',
    location: '',
    howItWorks: '',
    expectedBehavior: '',
    additionalDetails: ''
  };

  customerInfo: any;

  constructor(private modalCtrl: ModalController, private toaster: ToastMessageService) {}

  ngOnInit(): void {
    this.customerInfo = JSON.parse(localStorage.getItem('basic-info') || '{}');
  }

  submitReport(reportForm: NgForm): void {
    const reportData = {
      type: this.reportType,
      customer: this.customerInfo,
      bugReport: this.bugReport,
      featureRequest: this.featureRequest,
      timestamp: new Date()
    };

    let reportContent = `New ${reportData.type} report from ${reportData.customer.restaurantName} (${reportData.customer.emailId}):\n\n`;

    let subject = '';
    if (reportData.type === 'bug') {
      subject = 'Bug Report';
      reportContent += `
        Bug Report:
        Page URL: ${reportData.bugReport.page}
        What did the app do?: ${reportData.bugReport.appBehavior}
        Expected Behavior: ${reportData.bugReport.expectedBehavior}
        Additional Info: ${reportData.bugReport.additionalInfo}
      `;
    } else {
      subject = 'Feature Request';
      reportContent += `
        Feature Request:
        Feature Description: ${reportData.featureRequest.description}
        Location in App: ${reportData.featureRequest.location}
        How It Should Work: ${reportData.featureRequest.howItWorks}
        Expected Behavior: ${reportData.featureRequest.expectedBehavior}
        Additional Details: ${reportData.featureRequest.additionalDetails}
      `;
    }

    const templateParams = {
      subject: subject,
      reportContent: reportContent
    };

    console.log(templateParams);

    emailjs.send('service_r2e7qet', 'template_xjp3qim', templateParams, 'P6dUNR0zfjoIGYgH4')
      .then((response: EmailJSResponseStatus) => {
        console.log('Email sent successfully', response);
        this.toaster.successToastWithClose('Report submitted successfully.');
        reportForm.resetForm();
        this.modalCtrl.dismiss();
      }, (error) => {
        console.error('Error sending email', error);
        this.toaster.errorToastWithClose('Failed to submit the report.');
      });
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }
}
