import { Component } from '@angular/core';
import { ModalController, ViewWillEnter } from '@ionic/angular';
import { HandleSubscriptionsComponent } from 'src/app/appDirectives/handle-subscriptions.directive';

import { IProfile } from 'src/app/appModels/IProfile';
import { IStore } from 'src/app/appModels/IStore';
import { PaymentGatewayResolver } from 'src/app/appResolvers/payment-gateway.resolver';
import { StoreResolver } from 'src/app/appResolvers/store.resolver';
import { ClientTypeService } from 'src/app/appServices/client-type/client-type.service';
import { GlobalAuthResolver } from 'src/app/appServices/global-auth/global-auth.resolver';
import { PaymentGatewayService } from 'src/app/appServices/payment-gateway.service';
import { ToastMessageService } from 'src/app/core/utilities/toast-message.service';
import { StripeAccountModalComponent } from './stripe-account-modal/stripe-account-modal.component';
import { IBasicInfo } from 'src/app/appModels/IBasicInfo';
import { RestaurantInfoResolver } from 'src/app/appResolvers/restaurant-info.resolver';
import { RethinkAccountModalComponent } from './rethink-account-modal/rethink-account-modal.component';
import { SquareAccountModalComponent } from './square-account-modal/square-account-modal.component';
import { SquareService } from 'src/app/appServices/square.service';
import { AeropayAccountModalComponent } from './aeropay-account-modal/aeropay-account-modal.component';

@Component({
  selector: 'app-payment-methods',
  templateUrl: './payment-methods.page.html',
  styleUrls: ['./payment-methods.page.scss'],
})
export class PaymentMethodsPage extends HandleSubscriptionsComponent implements ViewWillEnter {

  paymentGateways: any[] = [];
  clients: any[] = [];

  emailId:string;
  loading: boolean = false;
  
  selectedStore: IStore;
  stripeAccount: string;
  rethinkAccount: string;
  squareAccount: string;
  aeropayAccount: string;
  basicInfo: IBasicInfo;

  constructor(
    private clientService: ClientTypeService,
    private paymentGatewayService: PaymentGatewayService,
    private toasterService: ToastMessageService,
    private _globalAuthResolver: GlobalAuthResolver,
    private _storeResolver: StoreResolver,
    private paymentResolver: PaymentGatewayResolver,
    private modalCtrl: ModalController,
    private _basicInfoResolver: RestaurantInfoResolver,
    private squareService: SquareService,
  ) {
    super();
   }

  ionViewWillEnter(): void {
    this._storeResolver.subscribeStoreList(true);
    this.subscribeBasicInfo();
    this.subscribeSelectedStore();
    this.getAllClients();
    this.getProfile();
  }


  /**
   * subscribe basic info
   */
  subscribeBasicInfo() {
    this._basicInfoResolver.restaurantBasicInfo.subscribe(res => {
      this.basicInfo = res;
    });
  }


  /**
   * Subscribe selected store
   */
  subscribeSelectedStore(){
    this.handleSubscription(this._storeResolver.selectedStore, 
    (res) => {
      if (res) {
        this.selectedStore = res;
        this.getPaymentTypes();
        this.getStripeAcByStoreId();
        this.getSquareAcByStoreId();
        this.getAeropayAcByStoreId();
        this.getRethinkAcByStoreId();
      }
    });
  }


  /**
   * Get Stripe Ac by Store id
   */
  getStripeAcByStoreId(){
    this.handleSubscription(this._storeResolver.getStripeAcByStoreId(this.selectedStore.id), 
    (res) =>{
      this.stripeAccount = res?.accountId;
    })
  }


  /**
   * Get square ac by store id
   */
  getSquareAcByStoreId(){
    this.handleSubscription(this._storeResolver.getSquareAcByStoreId(this.selectedStore.id), 
    (res) =>{
      this.squareAccount = res?.merchantId;
      this.checkAndExtendToken(res);
    })
  }


  /**
   * Check and extend token expiry
   * @param tokenObject 
   */
  // anup
  checkAndExtendToken(tokenObject: any): void {
    const expiryDate = new Date(tokenObject.expiryDate);
    const currentDate = new Date();
    const oneDayBeforeExpiry = new Date(expiryDate);
    oneDayBeforeExpiry.setDate(expiryDate.getDate() - 1);

    // console.log('expiryDate => ',expiryDate);
    // console.log('currentDate => ',currentDate);
    // console.log('oneDayBeforeExpiry => ',oneDayBeforeExpiry);
    
    if (currentDate >= oneDayBeforeExpiry) {
      
      // console.log('called => ',oneDayBeforeExpiry);
      this.handleSubscription(this.squareService.extendToken(tokenObject.refreshToken), 
        (res) => {
          // console.log('res extend => ',res);
          this.toasterService.successToastWithClose('Token extended successfully'+ res);
        },
        (err) => {
          this.toasterService.errorToastWithClose('Error extending token '+ err);
        }
      );

    }
  }


  /**
   * Get Stripe Ac by Store id
   */
  getRethinkAcByStoreId(){
    this.handleSubscription(this._storeResolver.getRethinkAcByStoreId(this.selectedStore.id), 
    (res) =>{
      this.rethinkAccount = res?.merchantId;
    })
  }

  /**
   * Getter: Client Name
   */
  getClientName(name) {
    if (name == 'customer') {
      return 'Website';
    }
    else if (name === 'indoor-kiosk') {
      return 'Indoor Kiosk'
    }
    else if (name === 'outdoor-kiosk') {
      return 'Outdoor Kiosk'
    }
    else if (name === 'contactless') {
      return 'Contact Less'
    }
    else {
      return name;
    }
  }



  /**
   * Get All Clients
   */
  getAllClients() {
    this.handleSubscription(this.clientService.getAllClients(), 
    (res) => {
      this.clients = res;
    });
  }



  /**
   * Get Payment Types
   */
  getPaymentTypes() {
    if (!this.selectedStore) {
      return
    }
    this.handleSubscription(this.paymentGatewayService.getPaymentGatewayTypeList(), 
    (res) => {
      if (res) {
        this.paymentGateways = res;
        this.paymentGateways.forEach(gateway => {
          this.getClientsByStoreAndGateway(this.selectedStore.id, gateway.id);
        });
      }
    });
  }


  /**
   * get client by store and gateway
   * @param storeId 
   * @param paymentGatewayId 
   */
  getClientsByStoreAndGateway(storeId: number, paymentGatewayId: number) {
    this.handleSubscription(this.paymentGatewayService.getClientsByStoreAndGateway(storeId, paymentGatewayId), 
    (res) => {
      this.paymentGateways.map(e => {
        if (e.id === paymentGatewayId) {
          e.clients = res;
        }
      })
    })
  }

  

  /**
   * Check Selected Clients
   * @param client 
   * @param clientType 
   * @returns 
   */
  checkSelectedClients(client, clientType) {
    const selectedClient = this.paymentGateways?.find(
      gateway => gateway.name.toLowerCase() === clientType.toLowerCase())?.clients?.find(
      e=> e.name.toLowerCase() === client.name.toLowerCase()) 
      ? true : false;
      return selectedClient;
  }
  


  /**
   * Update Client
   * @param client 
   * @param clientType 
   */
  updateClient(client, clientType) {
    const paymentMethod = this.paymentGateways.find(e => e.name.toUpperCase() === clientType.toUpperCase());
    const active = !this.checkSelectedClients(client, clientType);

    this.handleSubscription(this.paymentGatewayService.updateClient(this.selectedStore.id, paymentMethod.id, client.id, active), 
    (res) => {
      this.toasterService.successToastWithClose('Successfully Updated');
      this.postUpdateClient(client, clientType);
    }, err => {
      this.toasterService.errorToastWithClose('Unable to update, Please contact us.');
    });

  }



  /**
   * Post update client
   * @param client 
   * @param clientType 
   */
  postUpdateClient(client, clientType) {
    let clients = this.paymentGateways.find(gateway => gateway.name.toLowerCase() === clientType.toLowerCase()).clients;
    let clientExist = clients.find(e => e.id === client.id);
    if (clientExist) {
      const index = clients.findIndex(i => i.id == client.id);
      clients.splice(index, 1);
    } else {
      clients.push(client);
    }
    this.paymentResolver.getPaymentMethods(this.selectedStore?.id);
  }




  /**
   * Get Profile
   */
  getProfile() {
    this.handleSubscription(this._globalAuthResolver.profile, 
    (res: IProfile) => {
      if (res) {
        this.emailId = res.emailId;
      }
    })
  }




  /**
   * Open Stripe Account Modal
   */
   async openStripeAccountModal() {
    const modal = await this.modalCtrl.create({
      component: StripeAccountModalComponent,
      cssClass: 'rounded-modal big',
      backdropDismiss: true,
      mode: 'md',
      componentProps: {
        selectedStripeAccount : this.stripeAccount,
      },
    });
    await modal.present();
    const { role , data} = await modal.onWillDismiss();
    if (role || data) this.getStripeAcByStoreId();

  }


  /**
   * Open Rethink Account Modal
   */
  async openRethinkAccountModal() {
    const modal = await this.modalCtrl.create({
      component: RethinkAccountModalComponent,
      cssClass: 'rounded-modal big',
      backdropDismiss: true,
      mode: 'md',
      componentProps: {
        selectedRethinkAccount : this.rethinkAccount,
      },
    });
    await modal.present();
    const { role , data} = await modal.onWillDismiss();
    if (role || data) this.getRethinkAcByStoreId();

  }


  /**
   * Open Stripe Account Modal
   */
  async openSquareAccountModal() {
    const modal = await this.modalCtrl.create({
      component: SquareAccountModalComponent,
      cssClass: 'rounded-modal big',
      backdropDismiss: true,
      mode: 'md',
      componentProps: {
        selectedSquareAccount : this.squareAccount,
      },
    });
    await modal.present();
    const { role , data} = await modal.onWillDismiss();
    if (role || data) this.getSquareAcByStoreId();

  }



  /** ------------------------------
   * Aeropay Account Methods
  ------------------------------- */


  /**
   * Get aeropay ac by store id
   */
  getAeropayAcByStoreId(){
    this.handleSubscription(this._storeResolver.getAeropayAcByStoreId(this.selectedStore.id), 
    (res) =>{
      this.aeropayAccount = res?.accountId;
    })
  }


  /**
   * Open Aeropay Account Modal
   */
  async openAeropayAccountModal() {
    const modal = await this.modalCtrl.create({
      component: AeropayAccountModalComponent,
      cssClass: 'rounded-modal big',
      backdropDismiss: true,
      mode: 'md',
      componentProps: {
        selectedAeropayAccount : this.aeropayAccount,
      },
    });
    await modal.present();
    const { role , data} = await modal.onWillDismiss();
    if (role || data) this.getAeropayAcByStoreId();

  }
   
  onClose() {
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }

  

}
