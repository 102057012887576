import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpConfigService } from 'src/app/core/httpConfig/httpConfigService';
import { IProduct } from 'src/app/appModels/IProduct';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  api = environment.baseUrl + "product";

  getAllProductEvent = new Subject();

  constructor(private apiConfig: HttpConfigService) { }

  addProduct(product: IProduct): Observable<any> {
    return this.apiConfig.post(this.api + "/create", product, { withCredentials: true });
  }

  getProductsCount(): Observable<any> {
    return this.apiConfig.get(this.api + "/count");
  }
  
  getAllProducts(menuId: number): Observable<any> {
    return this.apiConfig.get(this.api + "/getAll/" + menuId, { withCredentials: true });
  }
  getProductsByCategoryId(id: number): Observable<any> {
    return this.apiConfig.get(this.api + "?categoryId=" + id);
  }
  updateProduct(product: IProduct): Observable<any> {
    return this.apiConfig.put(this.api + "/update/" + product.id, product, { withCredentials: true });
  }
  getProductById(id: number): Observable<any> {
    return this.apiConfig.get(this.api + "/" + id, { withCredentials: true });
  }
  deleteProduct(id: number): Observable<any> {
    return this.apiConfig.delete(this.api + "/" + id, { withCredentials: true });
  }

  updateProductStatuc(id: number, status: boolean): Observable<any> {
    return this.apiConfig.patch(this.api + "/status/" + id + "/" + status, { withCredentials: true });
  }

  updateProductPrice(id: number, price: number): Observable<any> {
    return this.apiConfig.patch(this.api + "/update/price/" + id + "/" + price, { withCredentials: true });
  }

  mapStore(productId: number, productStoreModelList: any[]): Observable<any> {
    return this.apiConfig.patch(this.api + "/store/" + productId, productStoreModelList, { withCredentials: true });
  }

  getProductIdsByMenu(menuId: number): Observable<any> {
    return this.apiConfig.get(this.api + "/getAllIds/" + menuId);
  }

  mapOrderType(productId: number, ids: number[]): Observable<any> {
    return this.apiConfig.patch(this.api + "/mapOrderType/" + productId + "?orderTypeIds=" + ids , { withCredentials: true });
  }
  
  
  getProductsByTopping(toppingId: number): Observable<any> {
    return this.apiConfig.get(this.api + "/variant/" + toppingId);
  }

  /**
   * update product category
   * @param productId 
   * @param categoryId 
   * @returns 
   */
  updateProductCategory(productId: number, categoryId: number): Observable<any> {
    return this.apiConfig.patch(`${this.api}/category/${productId}/${categoryId}`, null);
  }


  /**
   * Get all products by page by category
   * @param menuId 
   * @param pageSize 
   * @param pageIndex 
   * @param search 
   * @param status 
   * @returns 
   */
  getAllProductsByPageWithCategory(menuId:number,pageSize: number, pageIndex: number, categoryId:number = 0, search: string, status:boolean): Observable<any> {
    if(categoryId===0){
      return this.apiConfig.get(`${this.api}/getAll/${menuId}/${pageSize}/${pageIndex}?search=${search}&status=${status}`);
    }
    return this.apiConfig.get(`${this.api}/getAll/${menuId}/${pageSize}/${pageIndex}?categoryId=${categoryId}&search=${search}&status=${status}`);
  }


  /**
   * update multiple product status
   * @param status 
   * @param productIds 
   * @returns 
   */
  updateMultiProductStatus(status: boolean, productIds: number[]): Observable<any> {
    return this.apiConfig.patch(`${this.api}/status/${status}`, productIds);
  }


  /**
   * Delete multiple products 
   * @param productIds 
   * @returns 
   */
  deleteMultiProduct(productIds: number[]): Observable<any> {
    return this.apiConfig.patch(this.api, productIds);
  }


  /**
   * Delete multiple products from biotrack and DB 
   * @param payload 
   * @returns 
   */
  deleteMultiProductFromBothPlace(payload: any[]): Observable<any> {
    return this.apiConfig.patch(`${environment.baseUrl}biotrack/multiple-inventory/destroy/schedule`, payload);
  }


   


}
