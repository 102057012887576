import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { HandleSubscriptionsComponent } from 'src/app/appDirectives/handle-subscriptions.directive';
import { PaymentGatewayResolver } from 'src/app/appResolvers/payment-gateway.resolver';
import { RestaurantInfoResolver } from 'src/app/appResolvers/restaurant-info.resolver';
import { SettingsResolver } from 'src/app/appResolvers/settings.resolver';
import { CashMgmtService } from 'src/app/appServices/cash-mgmt.service';
import { ProcessingFeeService } from 'src/app/appServices/processing-fee.service';
import { NumberUtility } from 'src/app/appUtilities/number.utility';
import { PaymentMethods } from 'src/app/core/enums/enums';
import { DesignUtilityServices } from 'src/app/core/utilities/design-utility.service';
import { ToastMessageService } from 'src/app/core/utilities/toast-message.service';
import { TermsStripeCardReaderComponent } from 'src/app/includes/terms-stripe-card-reader/terms-stripe-card-reader.component';

@Component({
  selector: 'app-charge-action-sheet',
  templateUrl: './charge-action-sheet.page.html',
  styleUrls: ['./charge-action-sheet.page.scss'],
})
export class ChargeActionSheetPage extends HandleSubscriptionsComponent implements OnInit {

  @Input() dataPayload: any;
  @Input() calculateSplittedFee: any;

  basicInfo: any;

  paymentMethods: any[];
  stripeProcessingFees: any = {};

  platformFee = 0;
  spendablePoints: number;
  remainingAmount: number;
  loyaltyPointValue: number;
  notEnoughPoints: boolean = false;
  isExpired: boolean = false;

  constructor(
    private modalCtrl: ModalController,
    private _paymentGatewayResolver: PaymentGatewayResolver,
    private infoStorage: RestaurantInfoResolver,
    private _settingResolver: SettingsResolver,
    private processingFeeService: ProcessingFeeService,
    private numberUtility: NumberUtility,
    private cashMgmtService: CashMgmtService,
    private toaster: ToastMessageService,
  ) {
    super();
  }

  ngOnInit() {
  this.getBasicInfo();
  this.subscribePaymentMethods();
  this.subscribeStripeProcessingFees();
  this.getloyaltyPointsValue();

  // Store the original total amount before applying any processing fee or coupon discount
  this.dataPayload.originalTotalAmount = this.dataPayload.totalAmount;

  // Remove processing fee if it already exists in the order
  if (this.dataPayload.processingFee) {
    this.dataPayload.totalAmount = this.dataPayload.totalAmount - this.dataPayload.processingFee;
    delete this.dataPayload.processingFee;
  }
}


  getloyaltyPointsValue() {
    this.handleSubscription(this.cashMgmtService.getloyaltyPoints(),
      (res) => {
        this.loyaltyPointValue = +res.value;
        this.calculateSpendablePoints();
      },
      (err) => {
        this.toaster.errorToastWithClose('Error: ' + err);
      }
    );
  }

  isSpecialTenantAndCoupon(): boolean {
    return this.basicInfo?.restaurantName === 'Bluntley' && this.dataPayload?.couponCode?.toUpperCase() === 'OWNER';
  }


  calculateSpendablePoints() {
    let totalAmount = this.dataPayload?.totalAmount || 0;

    // Check if the special tenant and coupon condition applies
    if (this.isSpecialTenantAndCoupon()) {
      // Get the original amount before applying the coupon discount
      totalAmount = this.dataPayload?.originalTotalAmount || totalAmount;
    }

    const loyaltyPoints = this.dataPayload?.customer?.loyaltyPoints || 0;

    // Calculate the spendable loyalty points (capped at available points)
    const pointsForTotalAmount = Math.ceil(totalAmount * this.loyaltyPointValue);
    this.spendablePoints = Math.min(pointsForTotalAmount, loyaltyPoints);

    // Calculate remaining amount after spending loyalty points
    const remainingAmountRaw = totalAmount - (this.spendablePoints / this.loyaltyPointValue);
    this.remainingAmount = Number(remainingAmountRaw) || 0;

    // Check if points are enough to cover the total amount
    this.notEnoughPoints = loyaltyPoints < pointsForTotalAmount;

    // Check if points are expired
    const orderDate = new Date(this.dataPayload?.dateTime).getTime();
    if (this.dataPayload?.customer?.loyaltyPointsExpiration < orderDate) {
      this.isExpired = true;
    }

  }


  updateLoyaltyPointsBalance() {
    const loyaltyPoints = this.dataPayload?.customer?.loyaltyPoints || 0;
    this.dataPayload.customer.loyaltyPoints = loyaltyPoints - this.spendablePoints;
    this.dataPayload.loyaltyPointsSpent = this.spendablePoints;
    this.dataPayload.amountSpent = 0.1;
    // this.dataPayload.amountSpent = (this.remainingAmount > 0) ? this.remainingAmount : 0;
  }



  squareTerminalAvailable: boolean = this.checkSquareTerminalDeviceId();
  checkSquareTerminalDeviceId() {
    return !!localStorage.getItem('squareTerminalDeviceId');
  }


  onSelectPaymentGateway(method) {
    if (method.title === 'Loyalty Points' && this.notEnoughPoints) {
      this.toaster.errorToastWithClose('Not enough loyalty points.');
      return;
    }

    this.dataPayload.paymentMethod = method;
    this.dataPayload.processingFee = this.calculateProcessingFee(this.dataPayload.paymentMethod) || 0;
    this.dataPayload.platformFee = this.platformFee;

    if (method.title !== PaymentMethods.CashOnDelivery) {
      this.dataPayload.totalAmount = (this.dataPayload.totalAmount || 0) + this.dataPayload.processingFee;
      this.dataPayload.platformFee = this.getPlatformFeeFromRules(this.dataPayload.totalAmount,
      this.stripeProcessingFees.platformFeeRules, this.stripeProcessingFees?.platformFee);
      if (method.title === PaymentMethods.SquareTerminal) {
        const deviceId = localStorage.getItem('squareTerminalDeviceId');
        this.dataPayload.deviceId = deviceId;
      }
    }

    // Update loyalty points balance after the payment method is selected
    if (method.title === 'Loyalty Points') {
      this.updateLoyaltyPointsBalance();
    }

    this.dismissModal(true);
  }






  subscribePaymentMethods() {
    this.handleSubscription(this._paymentGatewayResolver.paymentMethods,
      (res) => {
        if (res) {
          this.paymentMethods = res;
        }
      }
    );
  }

  subscribeStripeProcessingFees() {
    this.handleSubscription(this._settingResolver.stripeProcessingFee,
      (res) => {
        this.stripeProcessingFees = res;
      }
    );
  }

  calculateProcessingFee(paymentMethod?: any): number {
    if (this.calculateSplittedFee) {
      const splittedFee = this.calculateSplittedFee.find(fee => fee.title === paymentMethod.title);
      return splittedFee ? splittedFee.fee : 0;
    } else {
      if (paymentMethod.name) {
        if (paymentMethod.name == 'Square Terminal') {
          return this.processingFeeService.calculateSquareTerminalProcessingFee(
            paymentMethod.title,
            this.dataPayload.totalAmount,
            this.stripeProcessingFees
          );
        } else if (paymentMethod.name == 'Square Card') {
          return this.processingFeeService.calculateSquareCardProcessingFee(
            paymentMethod.title,
            this.dataPayload.totalAmount,
            this.stripeProcessingFees
          );
        } else {
          return this.processingFeeService.calculateStripeProcessingFee(
            paymentMethod.title,
            this.dataPayload.totalAmount,
            this.stripeProcessingFees
          );
        }
      } else {
        return 0;
      }
    }
  }

  dismissModal(result: boolean) {
    if (result == false) {
      delete this.dataPayload.paymentMethod;
    }
    this.modalCtrl.dismiss();
  }

  getBasicInfo() {
    localStorage.removeItem('basic-info');

    this.handleSubscription(this.infoStorage.restaurantBasicInfo,
      (res) => {
        if (res) {
          this.basicInfo = res;
        }
      }
    );
  }

  async openTermsAndConditions() {
    if (this.basicInfo?.businessType === 'restaurant') {
      const modal = await this.modalCtrl.create({
        component: TermsStripeCardReaderComponent,
        componentProps: {
          data: this.dataPayload,
        },
      });
      await modal.present();
    } else {
      this.toaster.errorToastWithClose('Terms and conditions are only available for restaurant businesses.');
    }
  }

  getPlatformFeeFromRules(totalAmount: number, platformFeeRules: any[], defaultPlatformFee: number): number {
    let result = 0;
    for (let i = 0; i < platformFeeRules.length; i++) {
      const min = platformFeeRules[i].minOrderAmount;
      const max = platformFeeRules[i].maxOrderAmount;
      if (totalAmount > min && totalAmount <= max) {
        result = platformFeeRules[i].fee;
        break;
      }
    }
    return result > 0 ? result : defaultPlatformFee;
  }

}
