import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { HandleSubscriptionsComponent } from 'src/app/appDirectives/handle-subscriptions.directive';

@Component({
  selector: 'app-edit-text',
  templateUrl: './edit-text.component.html',
  styleUrls: ['./edit-text.component.scss'],
})
export class EditTextComponent extends HandleSubscriptionsComponent implements OnInit {
  @Input() data: { title: string; text: any; icon: string};
  updatedText: any;
  


  constructor(
    private modalCtrl: ModalController
  ) { 
    super();
  }

  isNumberType(): boolean {
    return typeof this.data.text === 'number';
  }

   

  
  ngOnInit() {
    this.updatedText = this.data.text;
  }


  
  /**
   * on Submit
   */
  onSubmit() {
    if (this.updatedText !== this.data.text) {
      this.modalCtrl.dismiss({ updatedText: this.updatedText });
    } else {
      this.modalCtrl.dismiss(); 
    }
  }


  
  closeModal() {
    this.modalCtrl.dismiss(null);
  }

  
}
