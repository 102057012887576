import { Component, OnInit } from '@angular/core';
import { Nav } from '../nav';
import { NavController } from '@ionic/angular';
import { Router } from '@angular/router';
import { HandleSubscriptionsComponent } from 'src/app/appDirectives/handle-subscriptions.directive';
import { RestaurantInfoResolver } from 'src/app/appResolvers/restaurant-info.resolver';
import { IBasicInfo } from 'src/app/appModels/IBasicInfo';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent extends HandleSubscriptionsComponent implements OnInit {
  minimize = false;
  public appPages:any[] = [];
  basicInfo: IBasicInfo;

  constructor(
    private navCtrl: NavController, 
    private router: Router,
    private _basicInfoResolver: RestaurantInfoResolver
  ) {
    super();
  }

  ngOnInit() {
    this.subscribeBasicInfo();
  }

  /**
   * subscribe basic info
   */
  subscribeBasicInfo() {
    this.handleSubscription(this._basicInfoResolver.restaurantBasicInfo,
      (res) => {
        this.basicInfo = res;
        this.appPages = Nav.filter(item => !item.businessType || item.businessType === this.basicInfo.businessType);
      }
    );
  }

  navigateRoot(url: string) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([url]);
    });
  }
}
